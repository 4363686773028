<template>
  <div>
    <van-cell-group title="">
      <van-field label="姓名" placeholder="请填写姓名" v-model="model.Visitor_Name"/>
      <van-field label="性别" readonly placeholder="请选择性别" v-model="model.Visitor_Sex" @click="sex.show = true"/>
      <van-popup v-model="sex.show" position="bottom">
        <van-picker :columns="sex.ls" @confirm="changeSex" show-toolbar @cancel="sex.show = false"/>
      </van-popup>

      <van-field label="访问日期" placeholder="请选择访问日期" readonly v-model="model.Visite_Date"
                 @click="date.show = true"/>
      <van-calendar v-model="date.show" @confirm="dateSel" :show-confirm="false" :allow-same-day="true"
                    :min-date="new Date('2021/7/1')"/>

      <van-field label="身份证号" placeholder="请输入身份证号" v-model="model.Visitor_Id_Numer"/>
      <van-field label="联系电话" placeholder="请输入联系电话" type="digit" v-model="model.Visitor_Mobile"/>
      <van-field label="工作单位" placeholder="请填写工作单位" v-model="model.Visitor_Work_Place"></van-field>

      <van-field label="来访原因" placeholder="请填写来访原因" readonly v-model="rea.tea"
                 @click="rea.show = true"></van-field>
      <van-popup v-model="rea.show" position="bottom">
        <van-picker :columns="rea.ls" @confirm="changeReason" show-toolbar @cancel="rea.show = false"/>
      </van-popup>
      <template v-if="rea.tea == '其他'">
        <van-field label="其他来访原因" placeholder="请填写来访原因" v-model="model.Reason"></van-field>
      </template>

      <van-field label="被访问人" placeholder="请填写被访问人" v-model="model.Be_Visited_Name"></van-field>
    </van-cell-group>

    <div style="padding: 20px; text-align: center">
      <van-button type="info" size="normal" icon="success" style="width: 90%;" @click="itemOk">完 成</van-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {Toast} from "vant";

export default {
  data() {
    return {
      model: {
        Reason: ''
      },

      sex: {
        show: false,
        ls: ['男', '女']
      },
      date: {show: false},
      rea: {show: false, ls: ['会议', '访问', '其他'], tea: ''}
    }
  },
  methods: {
    changeSex(e) {
      this.model.Visitor_Sex = e
      this.sex.show = false
    },
    dateSel(e) {
      this.model.Visite_Date = moment(e).format('YYYY-MM-DD');
      this.date.show = false;
    },
    changeReason(e) {
      if (e == '其他') {
        this.rea.show = false
        this.rea.tea = e
        this.model.Reason = ''
      } else {
        this.rea.show = false
        this.model.Reason = e
        this.rea.tea = e
      }
    },
    itemOk() {
      let self = this;
      this.model.SchoolId = this.$route.query.schoolId
      this.whale.remote.getResult({
        url: "/api/Mobile/VIS/VisRegister/CreateNew",
        data: self.model,
        completed: function (its) {
          console.log(its)
          Toast.success('谢谢填写！')
          self.model = {}
          // self.$router.push('/visitor/index')
        }
      })
    }
  },
}
</script>
<style lang="less" scoped></style>